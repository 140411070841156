import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
});

export default function CalendarGrid({
  event,
  currentDate,
  setCurrentDate,
  selectedDates,
  setSelectedDates,
  submitted,
  isEditing,
  userName,
  allAvailabilities
}) {
  const handleSelectSlot = ({ start, end, action }) => {
    const eventStart = new Date(event.startDate);
    eventStart.setHours(0, 0, 0, 0);
    const eventEnd = new Date(event.endDate);
    eventEnd.setHours(23, 59, 59, 999);
    
    const selectionStart = new Date(start);
    selectionStart.setHours(0, 0, 0, 0);
    
    if (selectionStart < eventStart || selectionStart > eventEnd) {
      return;
    }

    const selectionEnd = new Date(end);
    if (action === 'select') {
      selectionEnd.setDate(selectionEnd.getDate() - 1);
    }
    selectionEnd.setHours(0, 0, 0, 0);

    if (selectionEnd < eventStart || selectionEnd > eventEnd) {
      return;
    }

    if (action === 'click') {
      const dateStr = format(start, 'yyyy-MM-dd');
      setSelectedDates(prev => {
        const newDates = prev.includes(dateStr)
          ? prev.filter(d => d !== dateStr)
          : [...prev, dateStr];
        return newDates;
      });
      return;
    }

    const dates = [];
    let currentDateIter = new Date(selectionStart);
    
    while (currentDateIter <= selectionEnd) {
      const dateStr = format(currentDateIter, 'yyyy-MM-dd');
      dates.push(dateStr);
      currentDateIter.setDate(currentDateIter.getDate() + 1);
    }

    setSelectedDates(prev => {
      const allSelected = dates.every(date => prev.includes(date));
      const finalDates = allSelected 
        ? prev.filter(d => !dates.includes(d))
        : [...new Set([...prev, ...dates])];
      return finalDates;
    });
  };

  return (
    <div className="min-h-[600px] h-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <BigCalendar
        localizer={localizer}
        events={[
          ...selectedDates.map(date => ({
            title: userName ? userName : '\u00A0',
            start: new Date(date),
            end: new Date(date),
            allDay: true,
            color: '#3B82F6'
          })),
          ...(submitted ? allAvailabilities
            .filter(a => a.name !== userName && Array.isArray(a.dates))
            .flatMap(({name, dates}) => 
              dates.map(date => ({
                title: name,
                start: new Date(date),
                end: new Date(date),
                allDay: true,
                color: '#10B981'
              }))
            ) : [])
        ]}
        selectable={!submitted || isEditing}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={() => {}}
        date={currentDate}
        defaultView="month"
        views={['month']}
        onNavigate={(date) => {
          const targetDate = new Date(date);
          const eventStart = new Date(event.startDate);
          const eventEnd = new Date(event.endDate);

          const beforeEventStart = targetDate.getFullYear() < eventStart.getFullYear() || 
              (targetDate.getFullYear() === eventStart.getFullYear() && 
               targetDate.getMonth() < eventStart.getMonth());

          const afterEventEnd = targetDate.getFullYear() > eventEnd.getFullYear() || 
              (targetDate.getFullYear() === eventEnd.getFullYear() && 
               targetDate.getMonth() > eventEnd.getMonth());

          let newDate;
          if (beforeEventStart) {
            newDate = new Date(eventStart.getFullYear(), eventStart.getMonth(), 1);
          } else if (afterEventEnd) {
            newDate = new Date(eventEnd.getFullYear(), eventEnd.getMonth(), 1);
          } else {
            newDate = targetDate;
          }
          
          setCurrentDate(newDate);
          return newDate;
        }}
        dayPropGetter={date => {
          const eventStart = new Date(event.startDate);
          const eventEnd = new Date(event.endDate);
          eventStart.setHours(0, 0, 0, 0);
          eventEnd.setHours(23, 59, 59, 999);

          if (date < eventStart || date > eventEnd) {
            return {
              className: 'rbc-off-range-bg rbc-off-range',
              style: {
                cursor: 'not-allowed'
              }
            };
          }
          return {};
        }}
        className="dark:text-white selection-calendar"
        style={{ 
          height: '100%',
          '--selection-color': 'rgb(59 130 246 / 0.2)',
          '--invalid-selection-color': 'rgb(239 68 68 / 0.2)'
        }}
        selectionBackgroundPropGetter={(range) => {
          const start = new Date(range.start);
          const end = new Date(range.end);
          const eventStart = new Date(event.startDate);
          const eventEnd = new Date(event.endDate);
          eventStart.setHours(0, 0, 0, 0);
          eventEnd.setHours(23, 59, 59, 999);

          if (start < eventStart || end > eventEnd) {
            return {
              style: {
                backgroundColor: 'var(--invalid-selection-color)'
              }
            };
          }
          return {
            style: {
              backgroundColor: 'var(--selection-color)'
            }
          };
        }}
        eventPropGetter={event => ({
          className: 'calendar-event',
          style: { 
            backgroundColor: event.color
          }
        })}
        components={{
          event: (props) => (
            <div 
              className="calendar-event-content" 
              title={props.title}
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {props.title}
            </div>
          ),
          month: {
            dateHeader: ({ date, label }) => (
              <div className="rbc-date-cell">
                <span>{label}</span>
              </div>
            )
          }
        }}
        popup={false}
        showAllEvents={true}
        views={{
          month: true,
          week: false,
          day: false,
          agenda: false
        }}
        formats={{
          eventTimeRangeFormat: () => '',
          monthHeaderFormat: 'MMMM yyyy'
        }}
      />
    </div>
  );
}