import { useEffect, useState } from 'react';
import { getDateLocale, setDateLocale } from '../../utils/dateFormatter';

export default function LocaleToggle() {
  const [locale, setLocale] = useState(getDateLocale());

  const toggleLocale = () => {
    const newLocale = locale === 'de' ? 'en' : 'de';
    setLocale(newLocale);
    setDateLocale(newLocale);
    window.location.reload();
  };

  return (
    <button
      onClick={toggleLocale}
      className="control-button"
    >
      {locale.toUpperCase()}
    </button>
  );
}