import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getEvent, getEventAvailabilities, submitAvailability } from '../../services/events';
import { getUserSession, setUserSession } from '../../services/auth';
import CalendarGrid from './CalendarGrid';
import CalendarHeader from './CalendarHeader';
import AvailabilityOverview from './AvailabilityOverview';

export default function CalendarMain({ 
  copySuccess, 
  onShareClick,
  onShareVisibilityChange
}) {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [allAvailabilities, setAllAvailabilities] = useState([]);
  const [userName, setUserName] = useState(getUserSession(eventId));
  const [isEditing, setIsEditing] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showGreeting, setShowGreeting] = useState(false);

  useEffect(() => {
    const loadEvent = async () => {
      try {
        const eventData = await getEvent(eventId);
        if (!eventData) {
          console.error('Event not found');
          return;
        }
        setEvent(eventData);
        setCurrentDate(new Date(eventData.startDate));
        
        const availabilities = await getEventAvailabilities(eventId);
        setAllAvailabilities(availabilities);
        
        const userAvailability = availabilities.find(a => a.name === userName);
        if (userAvailability) {
          setSelectedDates(userAvailability.dates);
          setSubmitted(true);
        }
      } catch (error) {
        console.error('Error loading event:', error);
        alert('Failed to load event data');
      }
    };
    loadEvent();
  }, [eventId, userName]);

  useEffect(() => {
    if (event?.greetingMessage && !localStorage.getItem(`greeting-shown-${eventId}`)) {
      setShowGreeting(true);
      localStorage.setItem(`greeting-shown-${eventId}`, 'true');
    }
  }, [event, eventId]);

  const handleSubmit = async () => {
    let name = userName;
    if (!name) {
      name = prompt('Please enter your name:');
      if (!name) return;
      setUserName(name);
      setUserSession(eventId, name);
    }
    
    try {
      await submitAvailability(eventId, name, selectedDates);
      const updatedAvailabilities = await getEventAvailabilities(eventId);
      setAllAvailabilities(updatedAvailabilities);
      setSubmitted(true);
      setIsEditing(false);
    } catch (error) {
      console.error('Error submitting availability:', error);
      alert('Failed to submit availability. Please try again.');
    }
  };

  if (!event) return <div>Loading...</div>;

  return (
    <div className="space-y-4">
      <CalendarHeader
        event={event}
        userName={userName}
        showGreeting={showGreeting}
        setShowGreeting={setShowGreeting}
        copySuccess={copySuccess}
        onShareClick={onShareClick}
        onShareVisibilityChange={onShareVisibilityChange}
      />

      <CalendarGrid
        event={event}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        submitted={submitted}
        isEditing={isEditing}
        userName={userName}
        allAvailabilities={allAvailabilities}
      />

      <div className="flex justify-between items-center">
        {submitted ? (
          <div className="flex gap-4 items-center">
            <button
              onClick={() => setIsEditing(!isEditing)}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg"
            >
              {isEditing ? 'Cancel Edit' : 'Edit Dates'}
            </button>
            {isEditing && (
              <button 
                onClick={handleSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                Save Changes
              </button>
            )}
          </div>
        ) : (
          <button 
            onClick={handleSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
          >
            Submit Availability
          </button>
        )}
      </div>

      {submitted && (
        <>
          <div className="mt-4 p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
            <h3 className="text-lg font-semibold dark:text-white mb-2">
              People Available
            </h3>
            {allAvailabilities.map((availability, idx) => {
              const name = availability?.name || 'Anonymous';
              const dates = availability?.dates || [];
              return (
                <div key={idx} className="flex items-center gap-2 text-sm dark:text-gray-200">
                  <span>{name}:</span>
                  <span>{dates.length} days selected</span>
                </div>
              );
            })}
          </div>

          {allAvailabilities.length > 0 && (
            <AvailabilityOverview 
              eventId={eventId}
              event={event}
              allAvailabilities={allAvailabilities}
            />
          )}
        </>
      )}
    </div>
  );
}