export default function ShareButton({ copySuccess, onShareClick, className = '' }) {
  return (
    <button
      onClick={onShareClick}
      className={`share-button ${className} ${
        copySuccess ? 'share-button-success' : ''
      }`}
    >
      {className.includes('control-button') ? 'Share Link with others' : 'Share Link with others'}
    </button>
  );
}