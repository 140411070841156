import { useEffect } from 'react';
import { formatDate } from '../../utils/dateFormatter';
import ShareButton from '../Common/ShareButton';

export default function CalendarHeader({
  event,
  userName,
  showGreeting,
  setShowGreeting,
  copySuccess,
  onShareClick,
  onShareVisibilityChange
}) {
  useEffect(() => {
    const handleResize = () => {
      const shouldShowInHeader = window.innerWidth < 640;
      onShareVisibilityChange(shouldShowInHeader);
    };

    // Initial check
    handleResize();

    // Add resize listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [onShareVisibilityChange]);

  return (
    <>
      <div className="flex justify-between items-start">
        <div className="flex-shrink-0">
          <h1 className="text-2xl font-bold dark:text-white">
            {event.title}
            {userName && <span className="text-sm ml-2 text-gray-500">({userName})</span>}
          </h1>
        </div>
        
        <div className="flex flex-col items-center text-center mx-4 flex-grow">
          <div className="event-info">
            <div className="date-range">
              {formatDate(event.startDate)} - {formatDate(event.endDate)}
            </div>
            <div className="duration-info">
              Seeking availability for a {event.desiredDuration}-day period
            </div>
          </div>
        </div>

        <ShareButton 
          copySuccess={copySuccess}
          onShareClick={onShareClick}
          className="hidden sm:block w-[200px]"
        />
      </div>

      {showGreeting && event.greetingMessage && (
        <div className="mb-4 p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg border border-blue-200 dark:border-blue-800">
          <div className="flex justify-between items-center">
            <p className="text-blue-800 dark:text-blue-200">{event.greetingMessage}</p>
            <button 
              onClick={() => setShowGreeting(false)}
              className="ml-4 text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-200"
            >
              ×
            </button>
          </div>
        </div>
      )}
    </>
  );
}