import { useEffect, useState, useMemo } from 'react';
import { formatDate, toInternalDate } from '../../utils/dateFormatter';
import format from 'date-fns/format';

export const calculateBestPeriods = (availabilities, desiredDuration) => {
  if (!availabilities || !Array.isArray(availabilities) || !availabilities.length) return {
    desiredPeriods: [],
    shorterPeriods: []
  };

  const dateMap = {};
  availabilities.forEach(({name, dates}) => {
    if (!dates || !Array.isArray(dates)) return;
    
    dates.forEach(date => {
      if (!date) return;
      const dateStr = typeof date === 'string' ? date : format(new Date(date), 'yyyy-MM-dd');
      if (!dateMap[dateStr]) dateMap[dateStr] = [];
      dateMap[dateStr].push(name);
    });
  });

  const sortedDates = Object.keys(dateMap).sort();
  
  const findAllPeriods = (length) => {
    let periods = [];
    let maxPeopleCount = 0;

    for (let i = 0; i <= sortedDates.length - length; i++) {
      const periodDates = sortedDates.slice(i, i + length);
      const commonPeople = new Set(dateMap[periodDates[0]]);
      
      for (let j = 1; j < length; j++) {
        const currentDatePeople = new Set(dateMap[periodDates[j]]);
        for (const person of [...commonPeople]) {
          if (!currentDatePeople.has(person)) {
            commonPeople.delete(person);
          }
        }
        if (commonPeople.size === 0) break;
      }

      if (commonPeople.size > 0) {
        if (commonPeople.size > maxPeopleCount) {
          maxPeopleCount = commonPeople.size;
          periods = [{
            startDate: periodDates[0],
            endDate: periodDates[periodDates.length - 1],
            days: length,
            people: Array.from(commonPeople),
            peopleCount: commonPeople.size
          }];
        } else if (commonPeople.size === maxPeopleCount) {
          periods.push({
            startDate: periodDates[0],
            endDate: periodDates[periodDates.length - 1],
            days: length,
            people: Array.from(commonPeople),
            peopleCount: commonPeople.size
          });
        }
      }
    }
    return periods;
  };

  const desiredPeriods = findAllPeriods(desiredDuration);
  let shorterPeriods = [];
  let maxPeopleCount = desiredPeriods.length > 0 ? desiredPeriods[0].peopleCount : 0;

  // Look for shorter periods with more people
  for (let len = desiredDuration - 1; len >= 2; len--) {
    const periods = findAllPeriods(len);
    if (periods.length > 0) {
      const periodPeopleCount = periods[0].peopleCount;
      if (periodPeopleCount > maxPeopleCount) {
        maxPeopleCount = periodPeopleCount;
        shorterPeriods = periods;
      } else if (periodPeopleCount === maxPeopleCount) {
        shorterPeriods.push(...periods);
      }
    }
  }

  const result = {
    desiredPeriods,
    shorterPeriods: shorterPeriods.length > 0 && shorterPeriods[0].peopleCount > (desiredPeriods[0]?.peopleCount || 0) 
      ? shorterPeriods 
      : []
  };

  return result;
};

function AvailabilityOverview({ eventId, event, allAvailabilities }) {
  const bestDates = useMemo(() => {
    if (!allAvailabilities.length || !event?.desiredDuration) return null;
    return calculateBestPeriods(allAvailabilities, event.desiredDuration);
  }, [allAvailabilities, event?.desiredDuration]);

  if (!bestDates) return null;

  // Limit desired periods to 3
  const limitedDesiredPeriods = bestDates.desiredPeriods.slice(0, 3);

  // Filter shorter periods to only show different people combinations
  const uniqueShorterPeriods = bestDates.shorterPeriods.reduce((acc, period) => {
    const peopleKey = period.people.sort().join(',');
    if (!acc.peopleMap.has(peopleKey)) {
      acc.peopleMap.set(peopleKey, true);
      acc.periods.push(period);
    }
    return acc;
  }, { periods: [], peopleMap: new Map() }).periods.slice(0, 3);

  // Only show shorter periods if they have different people combinations
  const showShorterPeriods = uniqueShorterPeriods.some(shorter => 
    !limitedDesiredPeriods.some(desired => 
      shorter.people.sort().join(',') === desired.people.sort().join(',')
    )
  );

  return (
    <div className="mt-4 p-4 bg-green-50 dark:bg-green-900 rounded-lg">
      <h3 className="text-lg font-semibold text-green-800 dark:text-green-100">
        Availability Summary
      </h3>
      <div className="text-sm text-green-700 dark:text-green-200 space-y-4">
        {limitedDesiredPeriods.length > 0 && (
          <div>
            <p className="font-medium">
              {bestDates.desiredPeriods.length === 1 
                ? `Optimal ${event.desiredDuration}-day match:`
                : `Optimal ${event.desiredDuration}-day matches (showing ${limitedDesiredPeriods.length} of ${bestDates.desiredPeriods.length}):`}
            </p>
            {limitedDesiredPeriods.map((period, index) => (
              <div key={index} className="ml-4 mb-2">
                <p>{formatDate(period.startDate)} - {formatDate(period.endDate)}</p>
                <p>Available participants ({period.peopleCount}): {period.people.join(', ')}</p>
              </div>
            ))}
          </div>
        )}
        {showShorterPeriods && uniqueShorterPeriods.length > 0 && (
          <div>
            <p className="font-medium">
              Alternative {uniqueShorterPeriods[0].days}-day matches with more participants
              (showing {uniqueShorterPeriods.length} of {bestDates.shorterPeriods.length}):
            </p>
            {uniqueShorterPeriods.map((period, index) => (
              <div key={index} className="ml-4 mb-2">
                <p>{formatDate(period.startDate)} - {formatDate(period.endDate)}</p>
                <p>Available participants ({period.peopleCount}): {period.people.join(', ')}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AvailabilityOverview; 