import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createEvent } from '../../services/events';

export default function CreateEvent() {
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState({
    title: '',
    startDate: '',
    endDate: '',
    desiredDuration: 1,
    greetingMessage: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    
    setIsLoading(true);
    console.log('Submitting event details:', eventDetails);
    
    try {
      const eventId = await createEvent(eventDetails);
      console.log('Navigation to:', `/event/${eventId}`);
      navigate(`/event/${eventId}`, { replace: true });
    } catch (error) {
      console.error('Failed to create event:', error);
      alert('Failed to create event. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white dark:bg-gray-800 rounded-lg shadow p-6">
      <h2 className="text-2xl font-bold mb-4 dark:text-white">Create New Event</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium dark:text-gray-200">
            Event Title
          </label>
          <input
            type="text"
            value={eventDetails.title}
            onChange={(e) => setEventDetails({...eventDetails, title: e.target.value})}
            className="mt-1 block w-full rounded border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium dark:text-gray-200">
            Start Date
          </label>
          <input
            type="date"
            value={eventDetails.startDate}
            onChange={(e) => setEventDetails({...eventDetails, startDate: e.target.value})}
            className="mt-1 block w-full rounded border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium dark:text-gray-200">
            End Date
          </label>
          <input
            type="date"
            value={eventDetails.endDate}
            onChange={(e) => setEventDetails({...eventDetails, endDate: e.target.value})}
            className="mt-1 block w-full rounded border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium dark:text-gray-200">
            Desired Duration (days)
          </label>
          <input
            type="number"
            min="1"
            value={eventDetails.desiredDuration}
            onChange={(e) => setEventDetails({
              ...eventDetails, 
              desiredDuration: parseInt(e.target.value)
            })}
            className="mt-1 block w-full rounded border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium dark:text-gray-200">
            Greeting Message
          </label>
          <textarea
            value={eventDetails.greetingMessage}
            onChange={(e) => setEventDetails({...eventDetails, greetingMessage: e.target.value})}
            placeholder="Add a friendly message for your participants..."
            className="mt-1 block w-full rounded border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white h-24"
          />
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:bg-blue-300"
        >
          {isLoading ? 'Creating...' : 'Create Event'}
        </button>
      </form>
    </div>
  );
}