import { ref, set, get, child } from 'firebase/database';
import { db } from './firebase';

export const createEvent = async (eventDetails) => {
  console.log('Creating event with details:', eventDetails);
  try {
    const eventId = Math.random().toString(36).substr(2, 9);
    const event = {
      id: eventId,
      ...eventDetails,
      availabilities: []
    };
    
    console.log(`Writing event ${eventId} to Firebase:`, event);
    await set(ref(db, `events/${eventId}`), event);
    console.log('Event created successfully');
    return eventId;
  } catch (error) {
    console.error('Error creating event:', error);
    throw error;
  }
};

export const getEvent = async (eventId) => {
  console.log(`Fetching event: ${eventId}`);
  try {
    const snapshot = await get(child(ref(db), `events/${eventId}`));
    const event = snapshot.val();
    console.log('Fetched event data:', event);
    return event;
  } catch (error) {
    console.error('Error fetching event:', error);
    throw error;
  }
};

export const submitAvailability = async (eventId, name, dates) => {
  const snapshot = await get(child(ref(db), `events/${eventId}`));
  const event = snapshot.val();
  
  if (!event) return null;
  
  const availabilities = event.availabilities || [];
  const existingIndex = availabilities.findIndex(a => a.name === name);
  
  if (existingIndex >= 0) {
    availabilities[existingIndex].dates = dates;
  } else {
    availabilities.push({ name, dates });
  }
  
  event.availabilities = availabilities;
  await set(ref(db, `events/${eventId}`), event);
  return event;
};

export const getEventAvailabilities = async (eventId) => {
  const snapshot = await get(child(ref(db), `events/${eventId}/availabilities`));
  return snapshot.val() || [];
};