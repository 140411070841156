import { useState } from 'react';
import { ThemeProvider } from 'next-themes';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CreateEvent from './components/Event/CreateEvent';
import CalendarMain from './components/Calendar/CalendarMain';
import ThemeToggle from './components/Common/ThemeToggle';
import LocaleToggle from './components/Common/LocaleToggle';
import ShareButton from './components/Common/ShareButton';

function App() {
  const [copySuccess, setCopySuccess] = useState(false);
  const [showHeaderShare, setShowHeaderShare] = useState(false);
  
  const handleShareClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 3000);
  };

  const showHeaderShareButton = window.location.pathname.includes('/event/');

  return (
    <BrowserRouter>
      <ThemeProvider attribute="class" defaultTheme="system">
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors">
          <div className="container mx-auto px-4 py-2 sm:p-4">
            <div className="mb-4">
              <div className="language-theme-controls">
                {showHeaderShareButton && showHeaderShare && (
                  <ShareButton 
                    copySuccess={copySuccess}
                    onShareClick={handleShareClick}
                    className="control-button w-auto"
                  />
                )}
                <LocaleToggle />
                <ThemeToggle />
              </div>
            </div>
            <Routes>
              <Route path="/" element={<CreateEvent />} />
              <Route 
                path="/event/:eventId" 
                element={
                  <CalendarMain 
                    copySuccess={copySuccess}
                    onShareClick={handleShareClick}
                    onShareVisibilityChange={setShowHeaderShare}
                  />
                } 
              />
            </Routes>
          </div>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;