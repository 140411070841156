import { format, parse } from 'date-fns';
import { de, enUS } from 'date-fns/locale';

let currentLocale = localStorage.getItem('dateLocale') || 'de';

export const setDateLocale = (locale) => {
  currentLocale = locale;
  localStorage.setItem('dateLocale', locale);
};

export const getDateLocale = () => currentLocale;

export const formatDate = (dateString) => {
  // First ensure we have a valid date object
  let date;
  try {
    // If it's already a Date object
    if (dateString instanceof Date) {
      date = dateString;
    } else {
      // Parse from YYYY-MM-DD format (internal format)
      date = parse(dateString, 'yyyy-MM-dd', new Date());
    }
    
    const localeObj = currentLocale === 'de' ? de : enUS;
    const formatString = currentLocale === 'de' ? 'dd.MM.yyyy' : 'MM/dd/yyyy';
    return format(date, formatString, { locale: localeObj });
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Invalid Date';
  }
};

// Helper to ensure consistent internal date format
export const toInternalDate = (dateString) => {
  try {
    // If it's already in YYYY-MM-DD format, return as is
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return dateString;
    }
    
    // If it's a Date object or other format, convert to YYYY-MM-DD
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    return format(date, 'yyyy-MM-dd');
  } catch (error) {
    console.error('Date conversion error:', error);
    return null;
  }
};

export const getLocaleObject = () => currentLocale === 'de' ? de : enUS;