import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyBxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
  authDomain: "when2meet-e0dba.firebaseapp.com",
  databaseURL: "https://when2meet-e0dba-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "when2meet-e0dba",
  storageBucket: "when2meet-e0dba.appspot.com",
  messagingSenderId: "xxxxxxxxxxxx",
  appId: "1:xxxxxxxxxxxx:web:xxxxxxxxxxxxxxxx"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

console.log('Firebase Database initialized successfully');

export { db };